import * as React from "react";
import Layout from "@layout/index";
import Header from "@/components/head";

import {
  Text,
  Heading,
  Flex,
  Link as ChakraLink,
  VStack,
  Center,
} from "@chakra-ui/react";
import customStyles from "@/assets/theme/customStyles";

const { useRef, useState, useEffect, useMemo, memo } = React;

export interface ICookiesProps {}

export const Head = () => {
  return (
    <Header
      title="Cookie Declaration by Airgram.io | We Put Your Privacy and Data Security First"
      description="Cookie Declaration by Airgram. We respect users' privacy and data security. GDPR, CCPA and APPI compliant. SSL encrypted."
    />
  );
};

export const Cookies: React.FC<ICookiesProps> = (props) => {
  const {} = props;
  return (
    <Layout navMaxW={customStyles.web_max_w}>
      <VStack pb="40px" color="#170032" w="full">
        <Heading as="h1" textAlign="center">
          Cookie Declaration
        </Heading>
        <Center flexDir="column" maxW={customStyles.pure_text_max_w} gap="24px">
          <Flex w="full" py="24px" rowGap="24px" flexDir="column">
            <Text>
              This website uses cookies - Airgram. We use cookies to personalize
              content and analyze access to our website. By using the website,
              you agree to the use of cookies. For more information, please
              refer to our Cookie Declaration.
            </Text>
            <Text>
              Cookies are small text files that can be used by websites to make
              a user's experience more efficient.
            </Text>
            <Text>
              The law states that we can store cookies on your device if they
              are strictly necessary for the operation of this site. For all
              other types of cookies we need your permission.
            </Text>
            <Text>
              This site uses different types of cookies. Some cookies are placed
              by third party services that appear on our pages.
            </Text>
            <Text>
              You can at any time change or withdraw your consent from the
              Cookie Declaration on our website.
            </Text>
            <Text>
              Learn more about who we are, how you can contact us and how we
              process personal data in our Privacy Policy.
            </Text>
            <Text>
              Please state your consent ID and date when you contact us
              regarding your consent.
            </Text>
            <Text>
              Your consent applies to the following domains: www.airgram.io
            </Text>
            <Text>
              Your current state: Allow all. <br />
              Your consent ID:
              oK1dbSMxnIx9CPxmEPyCil4JabP98q9unEQ/oymm/sSLTaW4nYLLiQ== <br />
              Consent date: Wednesday, May 18, 2022 at 06:29:47 PM GMT+8
            </Text>

            <Text>
              <ChakraLink
                mr="10px"
                textDecor="underline"
                color="#9f2aec"
                href="https://www.airgram.io/cookies#"
              >
                Change your consent
              </ChakraLink>
              |
              <ChakraLink
                ml="10px"
                textDecor="underline"
                color="#9f2aec"
                href="https://www.airgram.io/cookies#"
              >
                Withdraw your consent
              </ChakraLink>
            </Text>
            <Text>
              Cookie declaration last updated on 7/3/22 by
              <ChakraLink
                ml="10px"
                textDecor="underline"
                color="#9f2aec"
                href="https://www.cookiebot.com/"
              >
                Cookiebot
              </ChakraLink>
              :
            </Text>
          </Flex>

          <Flex w="full" flexDir="column" p="8px" border="1px solid #333333">
            <Text fontWeight={700}>Necessary (7)</Text>
            <Text mb="10px">
              Necessary cookies help make a website usable by enabling basic
              functions like page navigation and access to secure areas of the
              website. The website cannot function properly without these
              cookies.
            </Text>
            <Flex py="5px" fontWeight="700" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={1}>
                Name
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Provider
              </Text>
              <Text wordBreak="break-word" flex={2}>
                Purpose
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Expiry
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Type
              </Text>
            </Flex>

            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={1}>
                __sharethis_cookie_test__
              </Text>
              <ChakraLink
                flex={1}
                mx="5px"
                color="#9f2aec"
                textDecor="underline"
                href="https://sharethis.com/privacy/"
              >
                Sharethis
              </ChakraLink>
              <Text wordBreak="break-word" flex={2} pr="10px">
                This cookie determines whether the browser accepts cookies.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>

            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={1}>
                CONSENT [x2]
              </Text>
              <Flex flexDir="column" wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  Google
                </ChakraLink>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  YouTube
                </ChakraLink>
              </Flex>

              <Text wordBreak="break-word" flex={2} pr="10px">
                Used to detect if the visitor has accepted the marketing
                category in the cookie banner. This cookie is necessary for
                GDPR-compliance of the website.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                2 years{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>

            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={1}>
                CookieConsent
              </Text>
              <Flex flexDir="column" wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://www.cookiebot.com/goto/privacy-policy/"
                >
                  Cookiebot
                </ChakraLink>
              </Flex>

              <Text wordBreak="break-word" flex={2} pr="10px">
                Stores the user's cookie consent state for the current domain
              </Text>
              <Text wordBreak="break-word" flex={1}>
                1 years{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>

            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={1}>
                intercom-id-#
              </Text>
              <Flex flexDir="column" wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://www.intercom.com/terms-and-policies#privacy"
                >
                  Intercom
                </ChakraLink>
              </Flex>

              <Text wordBreak="break-word" flex={2} pr="10px">
                Allows the website to recoqnise the visitor, in order to
                optimize the chat-box functionality.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                270 days{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>

            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={1}>
                intercom-session-#
              </Text>
              <Flex flexDir="column" wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://www.intercom.com/terms-and-policies#privacy"
                >
                  Intercom
                </ChakraLink>
              </Flex>

              <Text wordBreak="break-word" flex={2} pr="10px">
                Sets a specific ID for the user which ensures the integrity of
                the website’s chat function.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                270 days{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px">
              <Text wordBreak="break-word" flex={1}>
                test_cookie
              </Text>
              <Flex flexDir="column" wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  Google
                </ChakraLink>
              </Flex>

              <Text wordBreak="break-word" flex={2} pr="10px">
                Used to check if the user's browser supports cookies.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                1 days{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
          </Flex>

          <Flex w="full" flexDir="column" p="8px" border="1px solid #333333">
            <Text fontWeight={700}>Preferences (2)</Text>
            <Text mb="10px">
              Preference cookies enable a website to remember information that
              changes the way the website behaves or looks, like your preferred
              language or the region that you are in.
            </Text>
            <Flex py="5px" fontWeight="700" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                Name
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Provider
              </Text>
              <Text wordBreak="break-word" flex={2}>
                Purpose
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Expiry
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Type
              </Text>
            </Flex>

            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _hjCookieTest{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                www.airgram.io
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                This cookie is set by the audience manager of a website in order
                to determine if any additional third-party cookies can be set in
                the visitor’s browser – third-party cookies are used to gather
                information or track visitor behavior on multiple websites.
                Third-party cookies are set by a third-party website or company.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>

            <Flex py="5px">
              <Text wordBreak="break-word" flex={2}>
                intercom.intercom-state-#{" "}
              </Text>
              <Flex flexDir="column" wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  Google
                </ChakraLink>
              </Flex>

              <Text wordBreak="break-word" flex={2} pr="10px">
                Remembers whether the user has minimized or closed chat-box or
                pop-up messages on the website.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Persistent{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
          </Flex>

          <Flex w="full" flexDir="column" p="8px" border="1px solid #333333">
            <Text fontWeight={700}>Statistics (14)</Text>
            <Text mb="10px">
              Statistic cookies help website owners to understand how visitors
              interact with websites by collecting and reporting information
              anonymously.
            </Text>
            <Flex py="5px" fontWeight="700" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                Name
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Provider
              </Text>
              <Text wordBreak="break-word" flex={2}>
                Purpose
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Expiry
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Type
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _dc_gtm_UA-#{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  Google
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Used by Google Tag Manager to control the loading of a Google
                Analytics script tag.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                1 day{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _ga{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  Google
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Registers a unique ID that is used to generate statistical data
                on how the visitor uses the website.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                2 years{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _ga_#{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  Google
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Used by Google Analytics to collect data on the number of times
                a user has visited the website as well as dates for the first
                and most recent visit.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                2 years{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _gat
              </Text>
              <Text wordBreak="break-word" flex={1}>
                www.airgram.io
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Used by Google Analytics to throttle request rate
              </Text>
              <Text wordBreak="break-word" flex={1}>
                1 day{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _gid
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  Google
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Registers a unique ID that is used to generate statistical data
                on how the visitor uses the website.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                1 day{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _hjAbsoluteSessionInProgress{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://www.hotjar.com/legal/policies/privacy"
                >
                  Hotjar
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                This cookie is used to count how many times a website has been
                visited by different visitors - this is done by assigning the
                visitor an ID, so the visitor does not get registered twice.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                1 day{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _hjFirstSeen{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://www.hotjar.com/legal/policies/privacy"
                >
                  Hotjar
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                This cookie is used to determine if the visitor has visited the
                website before, or if it is a new visitor on the website.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                1 day{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _hjIncludedInPageviewSample{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                www.airgram.io
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Used to detect whether the user navigation and interactions are
                included in the website’s data analytics.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                1 day{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _hjIncludedInSessionSample{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://www.hotjar.com/legal/policies/privacy"
                >
                  Hotjar
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Registers data on visitors' website-behaviour. This is used for
                internal analysis and website optimization.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                1 day{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _hjSession_#{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://www.hotjar.com/legal/policies/privacy"
                >
                  Hotjar
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Collects statistics on the visitor's visits to the website, such
                as the number of visits, average time spent on the website and
                what pages have been read.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                1 day{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _hjSessionUser_#{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://www.hotjar.com/legal/policies/privacy"
                >
                  Hotjar
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Collects statistics on the visitor's visits to the website, such
                as the number of visits, average time spent on the website and
                what pages have been read.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                1 year{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _hjTLDTest{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://www.hotjar.com/legal/policies/privacy"
                >
                  Hotjar
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Registers statistical data on users' behaviour on the website.
                Used for internal analytics by the website operator.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                collect{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  Google
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Used to send data to Google Analytics about the visitor's device
                and behavior. Tracks the visitor across devices and marketing
                channels.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px">
              <Text wordBreak="break-word" flex={2}>
                personalization_id{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://twitter.com/en/privacy"
                >
                  Twitter Inc.
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                This cookie is set by Twitter - The cookie allows the visitor to
                share content from the website onto their Twitter profile.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                2 years{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
          </Flex>

          <Flex w="full" flexDir="column" p="8px" border="1px solid #333333">
            <Text fontWeight={700}>Marketing (19)</Text>
            <Text mb="10px">
              Marketing cookies are used to track visitors across websites. The
              intention is to display ads that are relevant and engaging for the
              individual user and thereby more valuable for publishers and third
              party advertisers.
            </Text>
            <Flex py="5px" fontWeight="700" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                Name
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Provider
              </Text>
              <Text wordBreak="break-word" flex={2}>
                Purpose
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Expiry
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Type
              </Text>
            </Flex>

            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                _gcl_au
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  Google
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Used by Google AdSense for experimenting with advertisement
                efficiency across websites using their services.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                3 months{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                ads/ga-audiences{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  Google
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Used by Google AdWords to re-engage visitors that are likely to
                convert to customers based on the visitor's online behaviour
                across websites.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Pixel Tracker
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                i/adsct [x2]{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://twitter.com/en/privacy"
                >
                  Twitter Inc.
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                The cookie is used by Twitter.com in order to determine the
                number of visitors accessing the website through Twitter
                advertisement content.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Pixel Tracker
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                IDE
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  Google
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Used by Google DoubleClick to register and report the website
                user's actions after viewing or clicking one of the advertiser's
                ads with the purpose of measuring the efficacy of an ad and to
                present targeted ads to the user.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                1 year{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                muc_ads
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://twitter.com/en/privacy"
                >
                  Twitter Inc.
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Collects data on user behaviour and interaction in order to
                optimize the website and make advertisement on the website more
                relevant.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                2 years{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                pagead/1p-user-list/#{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  Google
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Tracks if the user has shown interest in specific products or
                events across multiple websites and detects how the user
                navigates between sites. This is used for measurement of
                advertisement efforts and facilitates payment of referral-fees
                between websites.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Pixel Tracker
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                pagead/landing [x2]{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  Google
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Collects data on visitor behaviour from multiple websites, in
                order to present more relevant advertisement - This also allows
                the website to limit the number of times that they are shown the
                same advertisement.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Pixel Tracker
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                VISITOR_INFO1_LIVE{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  YouTube
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Tries to estimate the users' bandwidth on pages with integrated
                YouTube videos.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                179 day{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                YSC{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  YouTube
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Registers a unique ID to keep statistics of what videos from
                YouTube the user has seen.
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                ytidb::LAST_RESULT_ENTRY_KEY{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  YouTube
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Stores the user's video player preferences using embedded
                YouTube video
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Persistent
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTML Local Storage
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                yt-remote-cast-available{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  YouTube
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Stores the user's video player preferences using embedded
                YouTube video
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTML Local Storage
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                yt-remote-cast-installed{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  YouTube
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Stores the user's video player preferences using embedded
                YouTube video
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTML Local Storage
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                yt-remote-connected-devices{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  YouTube
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Stores the user's video player preferences using embedded
                YouTube video
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Persistent{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTML Local Storage
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                yt-remote-device-id{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  YouTube
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Stores the user's video player preferences using embedded
                YouTube video
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Persistent{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTML Local Storage
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                yt-remote-fast-check-period{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  YouTube
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Stores the user's video player preferences using embedded
                YouTube video
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTML Local Storage
              </Text>
            </Flex>
            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                yt-remote-session-app{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  YouTube
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Stores the user's video player preferences using embedded
                YouTube video
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTML Local Storage
              </Text>
            </Flex>
            <Flex py="5px">
              <Text wordBreak="break-word" flex={2}>
                yt-remote-session-name{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                <ChakraLink
                  mx="5px"
                  color="#9f2aec"
                  textDecor="underline"
                  href="https://policies.google.com/privacy"
                >
                  YouTube
                </ChakraLink>
              </Text>
              <Text wordBreak="break-word" flex={2} pr="10px">
                Stores the user's video player preferences using embedded
                YouTube video
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTML Local Storage
              </Text>
            </Flex>
          </Flex>

          <Flex w="full" flexDir="column" p="8px" border="1px solid #333333">
            <Text fontWeight={700}>Unclassified (3)</Text>
            <Text mb="10px">
              Unclassified cookies are cookies that we are in the process of
              classifying, together with the providers of individual cookies.
            </Text>
            <Flex py="5px" fontWeight="700" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                Name
              </Text>
              <Text wordBreak="break-word" flex={2}>
                Provider
              </Text>
              <Text wordBreak="break-word" flex={2}>
                Purpose
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Expiry
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Type
              </Text>
            </Flex>

            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2}>
                CookieName{" "}
              </Text>
              <Text wordBreak="break-word" flex={2}>
                airgram.upvoty.com{" "}
              </Text>
              <Text wordBreak="break-word" flex={2}>
                Pending
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Session
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>

            <Flex py="5px" borderBottom="1px solid #333333">
              <Text wordBreak="break-word" flex={2} overflow="hidden">
                iframe_session_airgram_upvoty_com_1_upvoty_site
              </Text>
              <Text wordBreak="break-word" flex={2}>
                airgram.upvoty.com
              </Text>
              <Text wordBreak="break-word" flex={2}>
                Pending
              </Text>
              <Text wordBreak="break-word" flex={1}>
                6 days{" "}
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTTP Cookie
              </Text>
            </Flex>

            <Flex py="5px">
              <Text wordBreak="break-word" flex={2} overflow="hidden">
                userleap.ids
              </Text>
              <Text wordBreak="break-word" flex={2}>
                cdn.sprig.com
              </Text>
              <Text wordBreak="break-word" flex={2}>
                Pending
              </Text>
              <Text wordBreak="break-word" flex={1}>
                Persistent
              </Text>
              <Text wordBreak="break-word" flex={1}>
                HTML Local Storage
              </Text>
            </Flex>
          </Flex>
        </Center>
      </VStack>
    </Layout>
  );
};
export default memo(Cookies);
